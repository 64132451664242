<template>
  <div>
    <b-col cols="auto">
      <small>Anzeige nur der 10 ersten Zeitpunkte</small>
      <div v-for="timestamp in object.timestamps_first" :key="timestamp.id">
        <div>{{ timestamp | datetime }}</div>
      </div>
    </b-col>
    <b-col v-if="object.timestamps_last.length > 0" cols="auto">
      <small>Anzeige nur der 10 letzten Zeitpunkte</small>
      <div v-for="timestamp in object.timestamps_last" :key="timestamp.id">
        <div>{{ timestamp | datetime }}</div>
      </div>
    </b-col>
  </div>
</template>

<script>
export default {
  name: "LastTimestamps",
  props: ['object']
}
</script>

<style lang="scss" scoped>
</style>
